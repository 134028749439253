import { render } from "./QAReport.vue?vue&type=template&id=99fc20fa&scoped=true"
import script from "./QAReport.vue?vue&type=script&lang=ts"
export * from "./QAReport.vue?vue&type=script&lang=ts"

import "./QAReport.vue?vue&type=style&index=0&id=99fc20fa&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-99fc20fa"

export default script
import QCard from 'quasar/src/components/card/QCard.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QCard,QItem,QItemSection,QItemLabel,QChip,QTable,QTr,QTd,QToggle,QBtn,QTooltip});
