<template>
  <CLoading :loading="loading" />
  <div class="row full-width q-mb-md test-mode">
    <TestModeSelector v-model="isTestMode" :isHideCopy="true" />
  </div>
  <q-card flat bordered class="row full-width q-mb-md">
    <q-item class="col-12 col-md-4 stat-item">
      <q-item-section>
        <q-item-label>{{ $t('label.audience_user.total_users') }}</q-item-label>
      </q-item-section>

      <q-item-section side>
        <q-chip color="primary" text-color="white">{{ numberWithCommas(totalAudienceUsers) }}</q-chip>
      </q-item-section>
    </q-item>

    <q-item class="col-12 col-md-4 stat-item">
      <q-item-section>
        <q-item-label>{{ $t('label.audience_user.active_users') }}</q-item-label>
      </q-item-section>

      <q-item-section side>
        <q-chip color="primary" text-color="white">{{ numberWithCommas(countActiveUsers) }}</q-chip>
      </q-item-section>
    </q-item>

    <q-item class="col-12 col-md-4 stat-item">
      <q-item-section>
        <q-item-label>{{ $t('label.audience_user.blocked_users') }}</q-item-label>
      </q-item-section>

      <q-item-section side>
        <q-chip color="primary" text-color="white">{{ numberWithCommas(countBlockedUsers) }}</q-chip>
      </q-item-section>
    </q-item>
  </q-card>

  <q-table
    bordered
    flat
    dense
    :columns="displayColumns"
    :rows="rows"
    v-model:pagination="pagination"
    :rows-per-page-options="rowPerPageOptions"
    row-key="id"
    ref="tableRef"
    :no-data-label="$t('messages.no_data_available')"
    class="audience-user text-grey-8"
    @request="onRequest"
    separator="cell"
  >
    <template v-slot:top>
      <div class="col-12 col-md-4 q-table__title text-black">{{ $t('label.audience_user.audience_user_list') }}</div>
    </template>

    <template v-slot:body="props">
      <q-tr :props="props">
        <q-td
          v-for="(column, index) in displayColumns"
          :key="column.field"
          :class="index == 0 ? 'is-active-col' : index == 1 ? 'user-id-col' : ''"
          class="col-right-line"
        >
          <template v-if="props.row[column.field] !== undefined && index == 0">
            <q-toggle v-model="props.row[column.field]" color="primary" disable />
          </template>
          <span v-if="props.row[column.field] !== undefined && (index == 1 || index == 2)">{{
            props.row[column.field]
          }}</span>
          <template v-else-if="props.row[column.field] !== undefined && (index == 3 || index == 4)">
            <template v-for="(value, propertyName, k) in props.row[column.field]" :key="k">
              <q-btn size="sm" unelevated rounded color="primary" class="q-mr-sm">
                {{ truncate(value['name'], 20, '...') }}
                <q-tooltip>{{ value['name'] }}</q-tooltip>
              </q-btn>
              <template v-if="(k + 1) % 3 == 0"> <br /> </template>
            </template>
          </template>

          <template v-else>
            <template v-for="(value, propertyName, k) in props.row[column.field]" :key="k">
              <q-btn size="sm" unelevated rounded color="primary" class="q-mr-sm">
                {{ truncate(value, 20, '...') }}
                <q-tooltip>{{ value }}</q-tooltip>
              </q-btn>
              <template v-if="(k + 1) % 3 == 0"> <br /> </template>
            </template>
          </template>
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { IAudienceUserHeader, IQuestion } from '@/utils/types'
import { ACTION_APP_USER, ACTION_AUDIENCE, ACTION_QUESTION } from '@/store/actions'
import { Watch } from 'vue-property-decorator'
import CLoading from '@/components/common/ui/CLoading.vue'
import { Formater } from '@/utils/formater'
import TestModeSelector from '@/components/common/ui/TestModeSelector.vue'
import { constant } from '@/utils/constants'
import BaseFormMixin from '../common/mixins/BaseFormMixin.vue'

@Options({
  components: { CLoading, TestModeSelector },
  directives: { maska },
})
export default class QAReport extends mixins(BaseFormMixin) {
  modalVisible = false
  loading = true
  columns: IAudienceUserHeader[] = []
  rows = []
  isTestMode = false
  pagination = {
    sortBy: 'desc',
    descending: false,
    page: 1,
    rowsPerPage: constant.ROW_PER_PAGE,
    rowsNumber: 10,
  }

  totalAudienceUsers = 0
  countActiveUsers = 0
  countBlockedUsers = 0

  get rowPerPageOptions() {
    return constant.ROW_PER_PAGE_OPTIONS
  }

  get selectedAppId() {
    return this.$route.params.app_id
  }

  get questions(): IQuestion[] {
    return this.$store.getters.questions
  }

  get displayColumns() {
    return this.columns.filter((item) => {
      if (item.is_display) {
        return true
      }
    })
  }

  @Watch('isTestMode')
  @Watch('selectedAppId', { immediate: true })
  async updateApi() {
    if (!this.selectedAppId) {
      return
    }

    this.loading = true
    await this.$store.dispatch(ACTION_QUESTION.LOAD_ITEMS, {
      app_id: this.selectedAppId,
    })

    const result = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_USER_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
    })

    if (result) {
      // eslint-disable-next-line
      this.totalAudienceUsers = result['total_audience_users']
      // eslint-disable-next-line
      this.countActiveUsers = result['count_active_users']
      // eslint-disable-next-line
      this.countBlockedUsers = result['count_blocked_users']
    }

    console.log(result, 'result')

    await this.loadTableHeader()
    // get initial data from server (1st page)
    this.$refs.tableRef.requestServerInteraction()
    this.loading = false
  }

  numberWithCommas(num) {
    return Formater.numberFormat(num)
  }

  async loadTableHeader() {
    this.columns = [
      {
        _id: 'is_active',
        label: this.$t('label.audience_user.is_active'),
        field: 'is_active',
        is_display: true,
        align: 'center',
      },
      {
        _id: 'user_id',
        label: this.$t('label.audience_user.user_id'),
        field: 'user_id',
        is_display: true,
        align: 'center',
      },
      {
        _id: 'display_name',
        label: this.$t('label.audience_user.display_name'),
        field: 'display_name',
        is_display: true,
        align: 'center',
      },
      {
        _id: 'source_campaign_list',
        label: this.$t('label.audience_user.source_campaign_list'),
        field: 'source_campaign_list',
        is_display: true,
        align: 'center',
      },
      {
        _id: 'source_list',
        label: this.$t('label.audience_user.source_list'),
        field: 'source_list',
        is_display: true,
        align: 'center',
      },
    ]
    for (const q of this.questions) {
      this.columns.push({
        _id: q._id,
        label: 'Q ' + q.title,
        field: q._id,
        is_display: false,
        align: 'center',
      })
    }
  }

  truncate(text, length, clamp) {
    clamp = clamp || '...'
    const node = document.createElement('div')
    node.innerHTML = text
    const content = node.textContent
    if (content) {
      return content.length > length ? content.slice(0, length) + clamp : content
    }

    return text
  }

  async onRequest(props) {
    const { page, rowsPerPage, sortBy, descending } = props.pagination
    const filter = props.filter
    this.loading = true
    this.pagination.rowsNumber = this.totalAudienceUsers
    const fetchCount = rowsPerPage === 0 ? this.totalAudienceUsers : rowsPerPage
    const startRow = (page - 1) * rowsPerPage
    const returnedData: [] = await this.fetchFromServer(startRow, fetchCount, filter, sortBy, descending, page)
    this.rows.splice(0, this.rows.length, ...returnedData)
    this.pagination.page = page
    this.pagination.rowsPerPage = rowsPerPage
    this.pagination.sortBy = sortBy
    this.pagination.descending = descending
    for (const user of this.rows) {
      Object.keys(user).forEach((element) => {
        const col = this.columns.find((item) => {
          if (item._id === element) {
            return true
          }
        })
        if (col) {
          col.is_display = true
        }
      })
    }

    this.loading = false
  }

  async fetchFromServer(startRow, count, filter, sortBy, descending, page) {
    const items = await this.$store.dispatch(ACTION_AUDIENCE.LOAD_USER_ITEM_DETAILS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
      filter: {
        start_row: startRow,
        rows_number: count,
        filter: filter,
        page: page,
        per_page: count,
        sort_by: sortBy,
        descending: descending,
      },
    })

    // TODO
    const resp = await this.$store.dispatch(ACTION_APP_USER.LOAD_ITEMS, {
      app_id: this.selectedAppId,
      is_test_mode: this.isTestMode,
      filter: {
        start_row: startRow,
        rows_number: count,
        page: page,
        per_page: count,
        filter: filter,
        sort_by: sortBy,
        descending: descending,
      },
    })

    console.log(resp, 'app users')

    return items
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/quasar.variables.scss';
.stat-item:not(:last-child) {
  border-radius: 0;
  border-right: 1px solid $separator-color;
}
.user-id-col {
  width: 200px;
}
.is-active-col {
  width: 100px;
}
.audience-user {
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}

.test-mode {
  margin-left: -8px !important;
}

.col-right-line {
  border-right: 1px solid #d0d2e0 !important;
}
</style>
